/* Print-specific styles */
#ctas, #subnav, #mainnav, #header-banner, 
#util, #bottomlinks, #credit 
{
	display: none;
}

#wrapper {
	width: 7in;
	padding-top: 1in;
	position: relative;
}
#header {
	position: absolute;
	left: 0;
	top: 0;
	height: 1in;
}
#content img {
	max-width: 2.5in;
	height: auto;
}
#footer {
	margin-top: 0.5in;
}
